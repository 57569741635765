import React from "react";
import Header from "../components/header/header";
import CountdownTimer from "../components/extras/countdown";
import HomeImage from '../images/headers/ouhome.png';    /* rename image when changed : see tip ## */
import Logo from '../images/MDM-Logo.png';
import '../App.css';
import '../style/home.css';
import CustomLink from "../components/navigation/customlink";
import {Link} from "react-router-dom";
import Map from "../components/extras/Map";

const Home = () => {
    return (
        <>
            <header>
                <span className="welcome">Join us February 28 - March 2, 2025 for the 7th annual</span>
                <Header text="MEDICAL DEVICE" text2=" MAKE-A-THON" tag="mdm" backgroundImage={HomeImage} />
                <div className="hosts">
                    <p>Hosted by the University of Oklahoma</p>
                    <p>Co-Hosted by the University of Illinois Urbana-Champaign</p>
                </div>
                <div className="social">
                    <Link to="/contact">
                        <img src={require("../images/mail.png")} alt={"mail"} loading={"lazy"}/>
                    </Link>
                    <Link to="https://www.instagram.com/medical_device_makeathon/" target="_blank" rel="noopener noreferrer">
                        <img src={require("../images/instagram-small.png")} alt={"insta"} loading={"lazy"}/>
                    </Link>
                </div>
            </header>

            <body>
            <div className="about">
                <div>
                    <h1> About Us </h1>
                    <p>
                        The Medical Device Make-A-Thon is a 48-hour competition where teams of students from diverse academic backgrounds
                        come together to conceptualize, design, and showcase a medical device solution to a real-world medical topic.
                    </p>
                    <p>
                        Participants will have the opportunity to showcase their creativity and
                        problem-solving skills while engaging in a fun, fast-paced environment.
                    </p>
                    <p>
                        Students from all over the country are invited to participate
                        in this fun-filled weekend of networking and innovation!
                    </p>
                </div>
            </div>

            <div className="more-info">
                <div className="info-box" id="workshop">
                    <h4>Workshop</h4>
                    <ul>
                        <li><span>Participate in SolidWorks, Fusion 360, and 3D Printing workshops to bring your designs to life</span></li>
                        <li><span>Receive mentorship from industry professionals to refine your design</span></li>
                        <li><span>Attend additional presentations designed to support your development</span></li>

                    </ul>
                </div>
                <div className="info-box">
                    <h4>Network</h4>
                    <ul>
                        <li><span>Engage with participants, industry professionals, and university faculty</span></li>
                        <li><span>Build connections with mentors and judges who are experts in their fields</span></li>
                        <li><span>Strengthen peer connections through collaboration and teamwork</span></li>
                    </ul>
                </div>
                <div className="info-box">
                    <h4>Compete</h4>
                    <ul>
                        <li><span>The top 15 teams will advance<br/>to the second round of judging</span></li>
                        <li><span>Showcase 3D-printed final prototypes to a panel of judges</span></li>
                        <li><span>Prize money will be awarded<br/>to the top 5 teams</span></li>
                    </ul>
                </div>
            </div>

            <div className="year-specific">
                <h1> Join Us! </h1>
                <div className="partnership">
                    <img src={require("../images/OU_Logo.png")} alt={"OU"} loading={"lazy"}/>
                    X
                    <img src={require("../images/I_logo.png")} alt={"UIUC"} loading={"lazy"}/>
                </div>
            </div>
            <div className="year-specific">
                <div>
                    <p>
                        {/*When & Where <br/>/*/}
                        Event will be located at the University of Oklahoma in Norman, Oklahoma
                    </p>
                    <br/>
                    <Link to="/previous-competitions" className="link-gallery">See what happened previous competitions</Link>
                    <p>
                        <br/>
                        Have Questions?<br/>We have answers! (see contact us page)
                    </p>
                </div>
                <div>
                    <Map></Map>
                </div>
            </div>
            <div className="schools">
                <h2> PARTICIPATING SCHOOLS </h2>
                <img src={require("../images/schools/University_of_Oklahoma_seal.png")} alt={"Oklahoma"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Illinois_seal.png")} alt={"Illinois"} loading="lazy"/>
                <img src={require("../images/schools/University_of_Iowa_seal.png")} alt={"Iowa"} loading="lazy"/>
            </div>

            <CountdownTimer />
            </body>
        </>
    );
};
export default Home;
