import { Link, useMatch, useResolvedPath} from "react-router-dom";
import CustomLink from "./customlink";
import React from "react";

const Navbar = () => {
    return (
        <nav className="nav">
            <Link to="/" className="site-name">
                {/*MDM*/}
                {/*<img src="/mdm2025.png" alt="MDM" className="logo-image" />*/}
                <img src="/mdmicon.png" alt="MDM" className="logo-image" />
                {/*<link rel="icon" href="%PUBLIC_URL%/mdmicon.png" />*/}
            </Link>
            <ul>
                <CustomLink to="https://docs.google.com/forms/d/e/1FAIpQLSfs4E8IjUiBhEhn-l_EPpb2YvoxWRhdsXsaQBOCFTexrtUqiw/viewform?usp=embedded_form_link" target="_blank" rel="noopener noreferrer">
                    Registration
                </CustomLink>
                {/*<CustomLink to="/register">Registration</CustomLink>*/}
                <CustomLink to="/sponsor">Sponsorship</CustomLink>
                {/*<CustomLink to="/history">Past Competitions</CustomLink>*/}
                <CustomLink to="/team">Meet Our Team</CustomLink>
                {/*<CustomLink to="/explore">Explore</CustomLink>*/}
                <CustomLink to="/contact">Contact Us</CustomLink>

            </ul>
        </nav>
    )
}
export default Navbar;
